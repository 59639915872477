import React from 'react'
import { Provider } from 'mobx-react';
import './features'
import OutlineManager from './ui/features/outline/outlineManager/OutlineManager';
import OutlineStore from './dataLayer/stores/OutlineStore';
import { Item, Menu } from 'react-contexify';

import "react-contexify/dist/ReactContexify.css";
import { useCallback } from 'react';
import { IOutlineItem } from './dataLayer/models/OutlineItem';
import PdfDocumentService from './features/pdfDocument/services/PdfDocumentService';


const outlineStore = OutlineStore.create();

export const App = () => {

	// Handler for assigning the selected bookmark to the visible page
	const handleAssignActualPage = useCallback((event): void => {
		const outlineItem: IOutlineItem = event.props.outlineItem;
		const currentPage = PdfDocumentService.getCurrentPage();
		console.log(`Assigning page ${currentPage} to outline item ${outlineItem.id}`);
		outlineItem.setPage(currentPage);
	}, []);

	// Handler for removing page assignment from the selected bookmark
	const handleRemovePageAssignment = useCallback((event): void => {
		const outlineItem: IOutlineItem = event.props.outlineItem;
		outlineItem.removePage();
	}, []);

	return (
		<Provider outlineStore={outlineStore}>
			<div style={{ padding: 8 }}>
				<OutlineManager />

			</div>
			<Menu id="outlineItemContextMenu">
				<Item onClick={handleAssignActualPage}>Aktuelle Seite zuweisen</Item>
				<Item onClick={handleRemovePageAssignment}>Zuweisung entfernen</Item>
			</Menu>
		</Provider>
	)
}
