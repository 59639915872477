import _ from "lodash";
import IOutlineItemModel from "../interfaces/IOutlineItem";
import OutlineItem, {IOutlineItem} from "../../../dataLayer/models/OutlineItem";
import PdfJsPage from "../models/PdfJsPage";
import PageReference from "../models/PageReference";

type InitializedHandler = (outline: IOutlineItem[]) => void;

/** Class for communicating with pdf.js's outline system */
class OutlineService {
	private readonly pdfJsPageCache: Map<string, PdfJsPage>;
	private readonly initializeHandlers: Map<string, InitializedHandler>;

	public constructor() {
		this.pdfJsPageCache = new Map<string, PdfJsPage>();
		this.initializeHandlers = new Map<string, InitializedHandler>();
	}

	public onInitialized(handler: InitializedHandler): string {
		const id = _.uniqueId();

		this.initializeHandlers.set(id, handler);

		return id;
	}

	public removeInitializedHandler(id: string): void {
		this.initializeHandlers.delete(id);
	}

	public async initialize(pdfDocument: any, pdfJsOutline: any): Promise<void> {
		let outlineItemModels: any = [];

		if (pdfJsOutline) {
			const outlineItems = await this.mapOutline(pdfDocument, pdfJsOutline);

			outlineItemModels = outlineItems.map((x) => this.mapToOutlineItem(x));
		}

		for (let handler of this.initializeHandlers.values()) {
			handler(outlineItemModels);
		}
	}

	private async mapOutline(pdfDocument: any, pdfJsOutline: any): Promise<IOutlineItemModel[]> {
		const outlineItems: IOutlineItemModel[] = [];

		for (let outlineItem of pdfJsOutline) {
			let mappedOutlineItem: IOutlineItemModel;

			// if the outline item has a destination, resolve the position and page
			if (outlineItem.dest) {
				const pageReference = new PageReference(outlineItem.dest[0].num, outlineItem.dest[0].gen);
				const pdfJsPage = await this.resolvePdfJsPage(pdfDocument, pageReference);

				mappedOutlineItem = {
					title: outlineItem.title,
					page: {
						index: pdfJsPage.pageIndex
					},
					items: []
				};
			} else {
				continue;
			}

			// resolve references of sub items
			if (outlineItem.items?.length) {
				mappedOutlineItem.items.push(...await this.mapOutline(pdfDocument, outlineItem.items));
			}

			outlineItems.push(mappedOutlineItem);
		}

		return outlineItems;
	}

	private async resolvePdfJsPage(pdfDocument: any, pageReference: PageReference): Promise<PdfJsPage> {
		// try to get the pdfJsPage from cache
		let pdfJsPage = this.pdfJsPageCache.get(pageReference.id);
		if (pdfJsPage) {
			return pdfJsPage;
		}

		// resolve the pdfJsPage
		const pageIndex = await pdfDocument.getPageIndex(pageReference);
		const pageNumber = pageIndex + 1;
		const page = await pdfDocument.getPage(pageNumber);
		pdfJsPage = new PdfJsPage(pageReference, page, pageIndex);

		// add pdfJsPage to cache
		this.pdfJsPageCache.set(pageReference.id, pdfJsPage);

		return pdfJsPage;
	}

	private mapToOutlineItem(model: IOutlineItemModel): IOutlineItem {
		return OutlineItem.create({
			title: model.title,
			page: model.page,
			items: model.items.map((x) => this.mapToOutlineItem(x))
		});
	}
}

export default new OutlineService();
