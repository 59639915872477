import React from "react";
import Nestable from "react-nestable";
import {FaChevronDown, FaChevronRight} from "react-icons/fa";
import ISortableContainerProps from "./ISortableContainerProps";

import "./SortableContainer.css";

// this usually goes once
// to the entry point of the whole app
// (e.g. src/index.js)
import "react-nestable/dist/styles/index.css";
import SortableItem from "../sortableItem/SortableItem";

const SortableContainer: React.FC<ISortableContainerProps> = ({items, onItemPress}) => {
	return (
		<Nestable
			items={items}
			renderCollapseIcon={({isCollapsed}) => isCollapsed ? <FaChevronRight className={"collapseIcon"}/> : <FaChevronDown className={"collapseIcon"}/>}
			renderItem={(props: any) => <SortableItem {...props} onPress={onItemPress}/>}
			handler={<></>}
		/>
	);
};

export default SortableContainer;
