/** Represents a reference to a page in the document used in outline items */
export default class PageReference {
	public constructor(
		public num: number,
		public gen: number  // Idk what this is
	) {
	}

	public get id(): string {
		return `${this.num}${this.gen}`;
	}
}
