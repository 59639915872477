import _ from 'lodash';
import { Instance, types } from 'mobx-state-tree';
import OutlineItem, { IOutlineItem } from '../models/OutlineItem';

const OutlineStore = types
  .model({
    items: types.array(OutlineItem) // All top-level bookmarks
  })
  .named('OutlineStore')
  .views(self => ({
    /** Get the parent bookmark of the bookmark with the given ID */
    getParentItem(childId: number): IOutlineItem | undefined {
      // First look only in the top-level bookmarks
      const parent = self.items.find(item => item.containsChild(childId));
      if (parent) {
        return parent;
      }

      // Second look in all child bookmarks
      const allItems = _.flatMap(self.items.map(item => item.childrenDeep));
      return allItems.find(x => x.containsChild(childId));
    },

    /** Create a new bookmark item with no assigned page */
    get newItem(): IOutlineItem {
      return OutlineItem.create({
        page: undefined
      });
    },

    /** Get all bookmarks, including all children of each bookmark */
    get itemsDeep(): IOutlineItem[] {
      const result = [...self.items];

      self.items.forEach(item => {
        result.push(...item.childrenDeep);
      });

      return result;
    }
  }))
  .actions(self => ({
    /** Create a new bookmark store with one empty bookmark in it */
    createEmpty(): void {
      self.items.replace([]);
      self.items.push(self.newItem);
    },

    /** Replace all bookmarks in the store with the given bookmarks */
    setItems(items: IOutlineItem[]): void {
      self.items.replace(items);
    },

    /** Add a new bookmark below the bookmark with the given ID */
    addItemBelow(itemId: number): void {
      const item = self.items.find(item => item.id === itemId);

      if (item) {
        // The bookmark is a top-level bookmark
        const itemIndex = self.items.indexOf(item) + 1;
        self.items.splice(itemIndex, 0, self.newItem);
      } else {
        // The bookmark is a child bookmark or does not exist
        const parent = self.getParentItem(itemId);
        if (!parent) {
          return;
        }

        const index = parent.getChildIndex(itemId) + 1;
        parent.addChild(self.newItem, index);
      }
    },

    /** Add a new bookmark above the bookmark with the given ID */
    addItemAbove(itemId: number): void {
      const item = self.items.find(item => item.id === itemId);

      if (item) {
        // The bookmark is a top-level bookmark
        const itemIndex = self.items.indexOf(item);
        self.items.splice(itemIndex, 0, self.newItem);
      } else {
        // The bookmark is a child bookmark or does not exist
        const parent = self.getParentItem(itemId);
        if (!parent) {
          return;
        }

        const index = parent.getChildIndex(itemId);
        parent.addChild(self.newItem, index);
      }
    },

    /** Save the bookmarks in a json structure that is compatible with media-processor-pdf */
    toMediaProcessorJson(): any[] {
      const json = self.items.map(item => item.toMediaProcessorJson());
      return json;
    }
  }));

export default OutlineStore;

export interface IOutlineStore extends Instance<typeof OutlineStore> {}
