import {Instance, types} from "mobx-state-tree";

const PageInformation = types.model({
	index: types.number
})
	.named("PageInformation")
	.views(self => ({
		/** Get the number of the page (starting with 1) */
		get number(): number {
			return self.index + 1;
		}
	}));

export default PageInformation;

export interface IPageInformation extends Instance<typeof PageInformation> {
}
