import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import useOutlineStore from '../../../../dataLayer/hooks/UseOutlineStore';
import { IOutlineItem } from '../../../../dataLayer/models/OutlineItem';
import OutlineService from '../../../../features/outline/services/OutlineService';
import ISortableItem from '../../sortable/interfaces/ISortableItem';
import SortableContainer from '../../sortable/sortableContainer/SortableContainer';
import IOutlineManagerProps from './IOutlineManagerProps';
import './OutlineManager.css';

type SortableOutlineItem = ISortableItem<{ outlineItem: IOutlineItem }>;

/** Map an outline item to a sortable outline item */
function mapToSortable(outlineItem: IOutlineItem): SortableOutlineItem {
  return {
    id: outlineItem.id,
    text: outlineItem.title,
    children: outlineItem.items.map(mapToSortable),
    metadata: {
      outlineItem
    }
  };
}

const OutlineManager: React.FC<IOutlineManagerProps> = () => {
  const outlineStore = useOutlineStore();

  const mappedOutlineItems = useMemo<SortableOutlineItem[]>(() => {
    return outlineStore.items.map(mapToSortable);
  }, [outlineStore.itemsDeep.length]);

  // Register the outline manager at the outline service to get the outline items once the outline service is ready
  useEffect((): (() => void) => {
    const handlerId = OutlineService.onInitialized((outlineItems): void => {
      outlineStore.setItems(outlineItems);
    });

    return (): void => {
      OutlineService.removeInitializedHandler(handlerId);
    };
  }, []);

  // Go to the page where the pressed outline item is pointing to
  const handleItemPress = useCallback((sortableItem: SortableOutlineItem): void => {
    const { metadata } = sortableItem;
    if (!metadata) return;

    metadata.outlineItem.navigateTo();
  }, []);

  // Handle the button press for creating a new outline (shown if no outline exists)
  const handleCreateEmptyPress = useCallback((): void => {
    outlineStore.createEmpty();
  }, []);

  // Handle the save button click
  const handleSavePress = () => {
    const json = outlineStore.toMediaProcessorJson();
    window.parent.postMessage(
      {
        topic: 'saveOutline',
        payload: json
      },
      '*'
    );
  };

  // No outline items exist
  if (!mappedOutlineItems.length) {
    return (
      <div>
        <span className={'noOutlineText'}>Kein Inhaltsverzeichnis vorhanden</span>
        <button className={'fullWidthButton'} onClick={handleCreateEmptyPress}>
          Inhaltsverzeichnis erstellen
        </button>
      </div>
    );
  }

  // Outline items exist
  return (
    <div>
      <SortableContainer items={mappedOutlineItems as any} onItemPress={handleItemPress} />
      <button className={'fullWidthButton'} onClick={handleSavePress}>
        Speichern
      </button>
    </div>
  );
};

export default observer(OutlineManager);
