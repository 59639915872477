import {IAnyModelType, Instance, types} from "mobx-state-tree";
import PdfDocumentService from "../../features/pdfDocument/services/PdfDocumentService";
import PageInformation from "./PageInformation";

let store = {index: 0};

/** Get an incrementing number for each bookmark */
function getNumber() {
	return store.index++;
}

const OutlineItem = types.model({																	// This represents one bookmark and its children
	id: types.optional(types.number, () => getNumber()),  	// An incrementing number for each bookmark
	title: types.optional(types.string, ""),  							// The title of the bookmark
	page: types.maybe(PageInformation),  																			// The page that is assigned to the bookmark (if no page is assigned, the value is undefined)
	items: types.array(types.late((): IAnyModelType => OutlineItem))  		// All direct children of the bookmark
})
	.named("OutlineItem")
	.views(self => ({
		/** Check if the given bookmark is a direct child of this bookmark */
		containsChild(id: number): boolean {
			return !!self.items.find(item => item.id === id);
		},

		/** Recursively get all children of this bookmark */
		get childrenDeep(): IOutlineItem[] {
			const result = [...self.items];

			self.items.forEach(item => {
				result.push(...item.childrenDeep);
			});

			return result;
		},

		/** Get the index (in the current level) of the child bookmark with the given ID */
		getChildIndex(id: number): number {
			return self.items.indexOf(self.items.find(item => item.id === id));
		},

		/** Get the number of the page that is assigned to this bookmark */
		get pageNumber(): number | undefined {
			return self.page?.number;
		}
	}))
	.actions(self => ({
		/** Navigate to the page that is assigned to this bookmark (if one is assigned)*/
		navigateTo(): void {
			if (!self.page) {
				return;
			}

			PdfDocumentService.changePage(self.page.number);
		},

		/** Add a child bookmark `offset` positions after this bookmark*/
		addChild(child: IOutlineItem, offset: number): void {
			self.items.splice(offset, 0, child);
		},

		/** Set the title of this bookmark */
		setTitle(title: string): void {
			self.title = title;
		},

		/**
		 * Assign a (new) page to this bookmark
		 *
		 * @param pageNumber The number of the page starting at 1
		 */
		setPage(pageNumber: number): void {
			self.page = PageInformation.create({index: pageNumber - 1});
		},

		/** Remove the page assignment from this bookmark */
		removePage(): void {
			self.page = undefined;
		},

		toMediaProcessorJson(): any {
			return {
				title: self.title,
				destination_page: self.page?.index,
				children: self.items.map(item => item.toMediaProcessorJson())
			};
		}
	}));

export default OutlineItem;

export interface IOutlineItem extends Instance<typeof OutlineItem> {
}
