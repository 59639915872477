import React from 'react'
import { render } from 'react-dom'
import './index.css'

import { App } from './App'

import './features'

console.log('BEFORE RENDER')

render(<App />, document.getElementById('outlineManagerView'))
