/** The class for communicating with pdf.js */
class PdfDocumentService {
	private pdfViewer: any;

	/** Initialize the service with an instance of pdf.js */
	public initialize(pdfViewer: any): void {
		this.pdfViewer = pdfViewer;
	}

	/** Change the page that is displayed */
	public changePage(pageNumber: number): void {
		this.pdfViewer.currentPageNumber = pageNumber;
	}

	/** Get the number of the page that is currently displayed */
	public getCurrentPage(): number {
		return this.pdfViewer.currentPageNumber;
	}
}

export default new PdfDocumentService();
