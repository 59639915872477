import React, { useCallback, useEffect, useState } from 'react';
import { FaBookmark, FaCaretDown, FaCaretUp, FaCheckCircle } from 'react-icons/fa';
import { observer } from 'mobx-react';
import { useContextMenu } from 'react-contexify';
import useOutlineStore from '../../../../dataLayer/hooks/UseOutlineStore';
import ISortableItemProps from './ISortableItemProps';
import './SortableItem.css';

const MENU_ID = 'outlineItemContextMenu';

const SortableItem: React.FC<ISortableItemProps> = ({ item, collapseIcon, handler, onPress }) => {
  const [editMode, setEditMode] = useState(item.text === '');
  const [newText, setNewText] = useState(item.text);
  const outlineStore = useOutlineStore();
  const { show } = useContextMenu({
    id: MENU_ID
  });

  const handleDoublePress = useCallback((): void => {
    setEditMode(true);
  }, []);

  const handlePress = useCallback((): void => {
    onPress(item);
  }, [item, onPress]);

  useEffect((): void => {
    setNewText(item.text);
  }, [item.text]);

  const handleEditDonePress = useCallback((): void => {
    setEditMode(false);
    (item.metadata as any).outlineItem.setTitle(newText);
  }, [item, newText]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      switch (e.code) {
        case 'Escape':
          // cancel editing and reset input
          setEditMode(false);
          setNewText(item.text);
          break;
        case 'Enter':
          // done editing
          handleEditDonePress();
          break;
      }
    },
    [item, handleEditDonePress]
  );

  const handleContextMenuPress = useCallback(
    (e: React.MouseEvent<SVGElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      show(e, { props: item.metadata });
    },
    [item]
  );

  return (
    <div className="sortableItem" onClick={handlePress} onDoubleClick={handleDoublePress}>
      {editMode ? null : handler}
      {collapseIcon}
      {editMode ? (
        <div className="inputWrapper">
          <input
            autoFocus
            onKeyDown={handleKeyDown}
            data-lpignore="true"
            autoComplete="off"
            type="text"
            value={newText}
            onChange={({ target }): void => {
              setNewText(target.value);
            }}
          />
        </div>
      ) : (
        <span className="label" title={(item.metadata as any).outlineItem.title}>
          {(item.metadata as any).outlineItem.title}
        </span>
      )}

      <div className="controls">
        {editMode ? (
          <FaCheckCircle title={'Speichern'} size={16} color="green" onClick={handleEditDonePress} />
        ) : (
          <>
            <FaCaretUp
              size={16}
              onClick={(): void => {
                outlineStore.addItemAbove((item.metadata as any).outlineItem.id);
              }}
              title={'Lesezeichen drüber hinzufügen'}
            />
            <FaCaretDown
              size={16}
              onClick={(): void => {
                outlineStore.addItemBelow((item.metadata as any).outlineItem.id);
              }}
              title={'Lesezeichen drunter hinzufügen'}
            />
          </>
        )}
      </div>
      <div className="controls">
        <FaBookmark textRendering="test" title={'Zuweisung ändern'} size={16} onClick={handleContextMenuPress} />
      </div>
      <div className="metadata">
        <span>{(item.metadata as any).outlineItem.pageNumber}</span>
      </div>
    </div>
  );
};

export default observer(SortableItem);
