import PageReference from "./PageReference";

/** Represents a pdf.js page */
export default class PdfJsPage {
	public constructor(
		public pageReference: PageReference,
		public pageInstance: any,
		public readonly pageIndex: number
	) {

	}

	public get pageNumber(): number {
		return this.pageIndex + 1;
	}
}
